import appStrings from '@/app/utility/string.utility';
import addEditCreateGrnAsset from '../grnAsset/addEditCreateGrnAsset';
import { required } from 'vuelidate/lib/validators';
import assetAudit from '../assetAudit';
import assetStatus from "./assetStatus";
import assetPutAway from "../assetPutAway";
import assetLiquidation from '../assetLiquidation';
import assetMatching from '../assetMatching';
// import commonHelper from '@/app/utility/common.helper.utility';
import transferAsset from "../transferAsset";
import Highcharts from 'highcharts';
import assetGlobalStatus from '../assetGlobalStatus'

export default {
  name: 'locationDash',
  components: { 
    addEditCreateGrnAsset,
    assetAudit,
    assetStatus,
    assetPutAway,
    assetLiquidation,
    transferAsset,
    assetMatching,
    assetGlobalStatus
   },
  data() {
    return {
      siteName:null,
      locSearch:null,
      department:{
        text:null,
        value:null
      },
      erpLoctnOrgId:null,
      locId:null,
      purchaseOrder: null,
      serialNumber: null,
      assetType: null,
      model: null,
      loader: false,
      isOpen: true,
      showValueSetModal:false,
      currentLoc: {
        text: null,
        value: null
      },
      locationDashData: [
        {
          value_code: 'AVAILABLE',
        },
        {
          value_code: 'DEPLOYED TO USER',
        },
        // {
        //   value_code: 'IN-REPAIR',
        // },
        {
          value_code: 'ALLOCATED',
        },
        // {
        //   value_code: 'SEND FOR LIQUIDATION',
        // },
        // {
        //   value_code: 'IN-TRANSIT',
        // },
        {
          value_code:'DEPLOYED TO FACILITY',
        },
        {
          value_code:'RMA',
        },
        {
          value_code:'INBOUND PURCHASE',
        },
        {
          value_code:'INBOUND TRANSIT',
        },
        {
          value_code:'IN LIQUIDATION',
        },
        {
          value_code:'LIQUIDATED',
        },
        {
          value_code: 'TOTAL',
        },
      ],
      locationDashFields: [
        {
          key: 'value_code',
          label:"",
          class:"value_code_font"
        },
        {
          key: 'laptop_qty',
          label:'Laptop',
          class:"alignItem"
        },
        {
          key: 'hhd_qty',
          label: 'HHD',
          class:"alignItem"
        },
        // {
        //   key: 'scanner_qty',
        //   label:'Barcode Scanner',
        //   class:"alignItem"
        // },
        {
          key: 'printer_qty',
          label:'HIP Printer',
          class:"alignItem"
        },
        {
          key:'bar_printer_qty',
          label: 'Barcode Printer',
          class:"alignItem"
        },
        {
          key:'cpu_qty',
          label: 'CPU',
          class:"alignItem"
        },
        {
          key:'monitor_qty',
          label: 'Monitor',
          class:"alignItem"
        },
        {
          key:'wireless_scanner_qty',
          label: 'Wireless Scanner',
          class:"alignItem"
        },
        {
          key:'wired_scanner_qty',
          label: 'Wired Scanner',
          class:"alignItem"
        },
        {
          key:'switch_qty',
          label: 'Wireless Scanner',
          class:"alignItem"
        },
        {
          key:'access_point_qty',
          label: 'Access Point',
          class:"alignItem"
        },
        {
          key:'server_qty',
          label: 'Server',
          class:"alignItem"
        },
        {
          key:'firewall_qty',
          label: 'Firewall',
          class:"alignItem"
        },
        {
          key:'others',
          label: 'Other Assets',
          class:"alignItem"
        }
      ],
      showAddGrnAssetModal: false,
      showHideAssetAuditModalFlag:false,
      showStatusModalFlag:false,
      showHideAssetPutAwayFlag:false,
      showHideLiquidationModalFlag:false,
      faId:null,
      assetData:{
        assetCategory:null,
        assetStatus:null,
        assetQty:null,
      },
      laptopQty:0,
      hhdQty:0,
      barcodeScanner:0,
      hipPrinter:0,
      barcodePrinter:0,
      cpuQty:0,
      monitorQty:0,
      wirelessScannerQty:0,
      wiredScannerQty:0,
      switchQty:0,
      accessPointQty:0,
      serverQty:0,
      firewallQty:0,
      otherAssetsQty:0,
      chartFlag: false,
      chartData:[],
      showHideOutboundModalFlag:false,
      showHideAssetMatchingFlag:false,
      showHideAssetGlobalStatusModalFlag:false,
      accessButtons:{},
      locAccessFlag:false,
      dashFlag:true,
    };
  },
  validations: {
    currentLoc: {
      text: {
        required
      }
    },
  },
  mounted() {
    this.totalLines()
  },
  methods: {
    totalLines() {
      let laptop = this.laptopQty;
      let hhd =this.hhdQty;
      // let barcodeScanner=this.barcodeScanner;
      let hipPrinter =this.hipPrinter;
      let barcodePrinter=this.barcodePrinter; 
      let cpu = this.cpuQty;
      let monitor=this.monitorQty;
      let wirelessScanner=this.wirelessScannerQty;
      let wiredScanner=this.wiredScannerQty;
      let switcch= this.switchQty;
      let accessPoint=this.accessPointQty;
      let server=this.serverQty;
      let firewall=this.firewallQty;
      let otherAssets=this.otherAssetsQty;
      // if(this.currentLoc.text=="YamunaNagar_DC (Haryana)"){
        this.chartData = [
          ['Laptop', laptop],
          ['HHD', hhd],
          // ['Barcode Scanner', barcodeScanner],
          ['HIP Printer', hipPrinter],
          ['Barcode Printer', barcodePrinter],
          ['CPU',cpu],
          ['Monitor',monitor],
          ['Wireless Scanner',wirelessScanner],
          ['Wired Scanner',wiredScanner],
          ['Switch',switcch],
          ['Access Point',accessPoint],
          ['Server',server],
          ['Firewall',firewall],
          ['Other Assets',otherAssets]
        ];
      this.chartFlag = true;
      this.showCharts()
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    hide() {},
    // openCreateGrnModal(){

    // },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if(this.vsetCode === appStrings.VALUESETTYPE.FA_DEPARTMENT){
          this.department.text=item.value_meaning;
          this.department.value=item.value_code;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
        this.currentLoc = {
          value: item.value_set_dtl_id,
          text: item.value_code,
        };
        this.locId=item.loc_id
        this.erpLoctnOrgId = item.erp_loc_org_id;
        this.faId=item.fa_loc_id;
        if(this.locId){
          this.getDashboardData()
        }
        this.getLocationAcess()
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      this.vsetCode=vsetCode
      if(this.vsetCode===appStrings.VALUESETTYPE.FA_DEPARTMENT){
        this.department.text= null,
        this.department.value= null
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
        this.currentLoc = {
          value: null,
          text: null
        }
        this.locationDashData= [
          {
            value_code: 'AVAILABLE',
          },
          {
            value_code: 'DEPLOYED TO USER',
          },
          // {
          //   value_code: 'IN-REPAIR',
          // },
          {
            value_code: 'ALLOCATED',
          },
          // {
          //   value_code: 'SEND FOR LIQUIDATION',
          // },
          {
            value_code:'DEPLOYED TO FACILITY',
          },
          {
            value_code:'RMA',
          },
          {
            value_code:'INBOUND PURCHASE',
          },
          {
            value_code:'INBOUND TRANSIT',
          },
          {
            value_code:'IN LIQUIDATION',
          },
          {
            value_code:'LIQUIDATED',
          },
          {
            value_code: 'TOTAL',
          },
        ]
        this.laptopQty = 0;
        this.hhdQty= 0;
        this.cpuQty=0;
        this.monitorQty=0;
        this.wirelessScannerQty=0;
        this.wiredScannerQty=0;
        this.switchQty=0;
        this.accessPointQty=0;
        this.serverQty=0;
        this.firewallQty=0;
        this.otherAssetsQty=0;
        this.barcodeScanner=0;
        this.hipPrinter= 0;
        this.barcodePrinter= 0;
        this.totalLines();
        this.showCharts();
        this.eventBus.$emit('upData', false)
      }
    },
    showHideAddGrnAsset(flag) {
      if(this.currentLoc.text){
        this.showAddGrnAssetModal = flag;
      }
      else{
        // alert('Kindly select a location from search parameter')
        this.$bvToast.toast("Kindly select a location from search parameter", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
      if(!flag){
        // this.getDashboardData()
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: true,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    showHideAssetAuditModal(flag){
      if(this.currentLoc.text){
        this.showHideAssetAuditModalFlag = flag;
      }
      else{
        // alert('Kindly select a location from search parameter')
        this.$bvToast.toast("Kindly select a location from search parameter", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
      if(!flag){
        // this.getDashboardData()
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    showHideLiquidationModal(flag){
      if(this.currentLoc.text){
        this.showHideLiquidationModalFlag = flag;
      }
      else{
        // alert('Kindly select a location from search parameter')
        this.$bvToast.toast("Kindly select a location from search parameter", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
      if(!flag){
        // this.getDashboardData()
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    clearData() {
      this.currentLoc = {
        value: null,
        text: null
      };
      this.locationDashData= [
        {
          value_code: 'AVAILABLE',
        },
        {
          value_code: 'DEPLOYED TO USER',
        },
        {
          value_code: 'IN-REPAIR',
        },
        {
          value_code: 'ALLOCATED',
        },
        {
          value_code: 'SEND FOR LIQUIDATION',
        },
        // {
        //   value_code: 'IN-TRANSIT',
        // },
        // {
        //   value_code:'PENDING INBOUND ASSET',
        // },
        {
          value_code:'RMA',
        },
        {
          value_code:'INBOUND PURCHASE',
        },
        {
          value_code:'INBOUND TRANSIT',
        },
        {
          value_code: 'TOTAL',
        },
      ]
    },
    showStatusModal(flag,status,cat,qty){
      this.showStatusModalFlag=flag
      if(this.showStatusModalFlag)
      {
        this.assetData={
          assetCategory:cat,
          assetStatus:status,
          assetQty:qty,
        }
    }else{
      // this.getDashboardData()
    }
    if(flag && !this.locAccessFlag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }else{
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      };
    }
  },
  showHideAssetPutAwayModal(flag){
    if(this.currentLoc.text){
      this.showHideAssetPutAwayFlag = flag;
    }
    else{
      // alert('Kindly select a location from search parameter')
      this.$bvToast.toast("Kindly select a location from search parameter", {
        title: 'Alert',
        variant: 'danger',
        solid: true
      });
    }
    if(!flag){
      // this.getDashboardData()
    }
    if(flag && !this.locAccessFlag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }else{
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      };
    }
  },
  showHideAssetMatchingModal(flag){
    if(this.currentLoc.text){
      this.showHideAssetMatchingFlag = flag;
    }
    else{
      // alert('Kindly select a location from search parameter')
      this.$bvToast.toast("Kindly select a location from search parameter", {
        title: 'Alert',
        variant: 'danger',
        solid: true
      });
    }
    if(!flag){
      // this.getDashboardData()
    }
    if(flag && !this.locAccessFlag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }else{
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      };
    }
  },
  getDashboardData(){
    this.dashFlag=false;
    this.laptopQty = 0;
    this.cpuQty=0;
    this.monitorQty=0;
    this.wirelessScannerQty=0;
    this.wiredScannerQty=0;
    this.switchQty=0;
    this.accessPointQty=0;
    this.serverQty=0;
    this.firewallQty=0;
    this.otherAssetsQty=0;
    this.hhdQty= 0;
    this.barcodeScanner=0;
    this.hipPrinter= 0;
    this.barcodePrinter= 0;
    const payload = {
        hrLocId:this.locId,
    };
    this.loader = true;
    this.$store
      .dispatch('assets/getDashboardData', payload)
      .then(resp => {
        this.loader = false;
        if (resp.status === 200) {
          this.dashFlag=true;
          this.locationDashData = resp.data.data;
          for(let i=0;i<this.locationDashData.length;i++){
             this.laptopQty += this.locationDashData[i].laptop_qty;
             this.hhdQty+= this.locationDashData[i].hhd_qty;
             this.barcodeScanner+= this.locationDashData[i].scanner_qty;
             this.hipPrinter+= this.locationDashData[i].printer_qty;
             this.barcodePrinter+= this.locationDashData[i].bar_printer_qty;
             this.cpuQty+= this.locationDashData[i].cpu_qty;
             this.monitorQty+= this.locationDashData[i].monitor_qty;
             this.wirelessScannerQty+= this.locationDashData[i].wireless_scanner_qty;
             this.wiredScannerQty+= this.locationDashData[i].wired_scanner_qty;
             this.switchQty+= this.locationDashData[i].switch_qty;
             this.accessPointQty+= this.locationDashData[i].access_point_qty;
             this.serverQty+= this.locationDashData[i].server_qty;
             this.firewallQty+= this.locationDashData[i].firewall_qty;
             this.otherAssetsQty+=this.locationDashData[i].others;

          }
          this.locationDashData.push({
            value_code:'TOTAL',
            laptop_qty:this.laptopQty,
            hhd_qty:this.hhdQty,
            scanner_qty:this.barcodeScanner,
            printer_qty:this.hipPrinter,
            bar_printer_qty:this.barcodePrinter,
            cpu_qty: this.cpuQty,
            monitor_qty:this.monitorQty,
            wireless_scanner_qty:this.wirelessScannerQty,
            wired_scanner_qty:this.wiredScannerQty,
            switch_qty:this.switchQty,
            access_point_qty:this.accessPointQty,
            server_qty:this.serverQty,
            firewall_qty:this.firewallQty,
            others:this.otherAssetsQty,
          })
          // this.locationDashData.map(ele=>{
          //   ele.bar_printer_qty=Number(ele.bar_printer_qty)
          //   ele.hhd_qty=Number(ele.hhd_qty)
          //   ele.laptop_qty=Number(ele.laptop_qty)
          //   ele.printer_qty=Number(ele.printer_qty)
          // })
          // this.totalRows = resp.data.data.total_elements;
          this.totalLines()
        }
      })
      .catch(() => {
        this.loader = false;
      });
  },
  showOpenOutboundModal(flag) {
    if(this.currentLoc.text){
      this.showHideOutboundModalFlag = flag;
    }
    else{
      // alert('Kindly select a location from search parameter')
      this.$bvToast.toast("Kindly select a location from search parameter", {
        title: 'Alert',
        variant: 'danger',
        solid: true
      });
    }
    if(!flag){
      // this.getDashboardData()
    }
    if(flag && !this.locAccessFlag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }else{
      this.accessButtons = {
        deleteAllow: true,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      };
    }
  },
  showCharts(){
    Highcharts.chart('chart-container1', {
      chart: {
        type: 'pie',
        height: 410,
        width:430,
        borderRadius:18,
        backgroundColor: 'rgb(255,255,255)',
      },  
      title: {
        text: this.currentLoc.text?this.currentLoc.text+'<br/>'+'Dashboard Status':'Dashboard Status',
        style:{
          color: 'black',
          fontWeight:'bold'
        }
      },
      plotOptions: {
        pie: {
           allowPointSelect: true,
            cursor: 'pointer',          
          dataLabels: {
            enabled: true,
            color:'black',
            format:  '<b>{point.name}</b><br>{point.percentage:.1f}%',
            style: {
              fontFamily: 'Open Sans',
              fontWeight:'x-small',
              textOutline:'none'
            },
            distance:20,
          }
        }
      },
        tooltip:{
       style: {
              fontFamily: 'Open Sans',
            }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [
        {
          name: 'Data',
          size: '70%',
          data: this.chartData,
          innerSize: '30%',
        }
      ]
    });
  },
  showHideAssetGlobalStatusModal(flag){
    this.showHideAssetGlobalStatusModalFlag=flag
    if (flag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }
    if(!flag){
      // this.getDashboardData()
    }
  },
  getLocationAcess(){
    const payload = {
      hrLocId:this.locId,
  };
  this.loader = true;
  this.$store
    .dispatch('assets/getLocationAccessFlag', payload)
    .then(resp => {
      this.loader = false;
      if (resp.status === 200) {
        this.locAccessFlag=resp.data.data
      this.eventBus.$emit('upData', resp.data.data)
      }
    })
    .catch(() => {
      this.loader = false;
    });
  },
  getData(){
    if(this.currentLoc.text){
      this.getDashboardData()
    }
    else{
      this.$bvToast.toast("Kindly select a location from search parameter", {
        title: 'Alert',
        variant: 'danger',
        solid: true
      });
    }
  }
 
},
  beforeDestroy() {
    // this.unsubscribe();
  }
};

